<template>
  <div id="print"><h1 style="text-align: center; font-weight: 400">上海奥吉实业有限公司</h1>
    <h3 style="text-align: center; font-weight: 800">{{ this.adjustPayData ? '付款调整单':'付款通知单'}}</h3>
    <div>
      <div style="display: flex;width: calc(100% - 40px);margin-bottom:15px;">
        <div style="flex:1">
          <label v-for="item in paymentList" :key="item.value">
            <span style="font-weight: 800;font-size: 16px" v-if="item.value === data.pay_method">付款方式：{{ item.name }}</span>
          </label>
        </div>
        付款单位:
        <!-- 堆场单独展示 -->
        <span class="custom-style" style="width: 200px;margin-right:10px;" v-if="data.department === 6">
          {{ data.yard_type_name }}
        </span>
        <!-- 其他部门展示 -->
        <span class="custom-style" style="width: 200px;margin-right:10px;" v-else>
          {{ this.GLOBAL.departmentMaps[data.department].name }}
        </span>
        <span>业务创建日期：</span>
        <span class="custom-style">{{ moment(this.data.apply_time).format('YYYY') }}</span>年
        <span class="custom-style">{{ moment(this.data.apply_time).format('MM') }}</span>月
        <span class="custom-style">{{ moment(this.data.apply_time).format('DD') }}</span>日
      </div>
      <div>
        <div style="font-weight: 800;font-size: 16px;margin-bottom: 0px;">
          <a-row :gutter="24">
            <a-col :span="21">
              <span>收款单位：{{ data.customer }}</span>
            </a-col>
            <a-col :span="3">
              <span>附件 {{ data.appendix_num ? data.appendix_num : "__" }} 张</span>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div>
      <div>
        <table>
          <tbody>
            <tr>
              <template v-if="GLOBAL.departmentMaps[data.department].name === '国贸部'">
                <th rowspan="2" style="text-align: center;width: 25%">业务编号</th>
                <th rowspan="2" style="text-align: center;width: 25%">报价编号</th>
                <th rowspan="2" colspan="2" style="text-align: center;width: 50%">应付费用</th>
              </template>
              <template v-else-if="GLOBAL.departmentMaps[data.department].name === '进销存'">
                <th rowspan="2" style="text-align: center">业务编号</th>
                <th rowspan="2" style="text-align: center">品名</th>
                <th rowspan="2" style="text-align: center">数量</th>
                <th rowspan="2" colspan="2" style="text-align: center;">应付费用</th>
              </template>
              <template v-else-if="GLOBAL.departmentMaps[data.department].name === '客服部'">
                <th rowspan="2" style="text-align: center">业务编号</th>
                <th rowspan="2" colspan="2" style="text-align: center;">应付费用</th>
              </template>
              <template v-else-if="GLOBAL.departmentMaps[data.department].name === '仓储部'">
                <th rowspan="2" style="text-align: center">业务编号</th>
                <th rowspan="2" colspan="2" style="text-align: center;">应付费用</th>
              </template>
              <template v-else-if="GLOBAL.departmentMaps[data.department].name === '堆场'">
                <th style="text-align: center">业务编号</th>
                <th style="text-align: center">业务时间</th>
                <th rowspan="2" colspan="2" style="text-align: center;">应付费用</th>
              </template>
              <template v-else-if="GLOBAL.departmentMaps[data.department].name === '综合部' ||
                GLOBAL.departmentMaps[data.department].name === '总经办'">
                <th style="text-align: center">部门</th>
                <th style="text-align: center">费用名称</th>
                <th style="text-align: center">金额</th>
                <th style="text-align: center">部门审核人</th>
              </template>
            </tr>
            <tr></tr>
            <template>
              <tr v-for="item in list" :key="item.id">
                <template v-if="GLOBAL.departmentMaps[data.department].name === '国贸部'">
                  <td style="text-align: center">{{ item.order_num }}</td>
                  <td style="text-align: center" >{{ item.quota_num }}</td>
                  <td style="text-align: center;width: 25%">{{ item.fee_name }}</td>
                  <td style="text-align: right;width: 25%">{{ moneyFormatter(item.amount) }}</td>
                </template>
                <template v-else-if="GLOBAL.departmentMaps[data.department].name === '进销存'">
                  <td style="text-align: center">{{ item.order_num }}</td>
                  <td style="text-align: center">{{ item.product_name }}</td>
                  <td style="text-align: center">{{ item.fee_num }}</td>
                  <td style="text-align: center;width: 25%">{{ item.fee_name }}</td>
                  <td style="text-align: right;width: 25%">{{ moneyFormatter(item.amount) }}</td>
                </template>
                <template v-else-if="GLOBAL.departmentMaps[data.department].name === '客服部'">
                  <td style="text-align: center">{{ item.order_num }}</td>
                  <td style="text-align: center">{{ item.fee_name }}</td>
                  <td style="text-align: right">{{ moneyFormatter(item.amount) }}</td>
                </template>
                <template v-else-if="GLOBAL.departmentMaps[data.department].name === '仓储部'">
                  <td style="text-align: center">{{ item.order_num }}</td>
                  <td style="text-align: center">{{ item.fee_name }}</td>
                  <td style="text-align: right">{{ moneyFormatter(item.amount) }}</td>
                </template>
                <template v-else-if="GLOBAL.departmentMaps[data.department].name === '堆场'">
                  <td style="text-align: center">{{ item.order_num }}</td>
                  <td style="text-align: center">{{ item.business_date }}</td>
                  <td style="text-align: center">{{ item.fee_name }}</td>
                  <td style="text-align: right">{{ moneyFormatter(item.amount) }}</td>
                </template>
                <template v-else-if="GLOBAL.departmentMaps[data.department].name === '综合部' ||
                  GLOBAL.departmentMaps[data.department].name === '总经办'">
                  <td style="text-align: center">{{ item.department }}</td>
                  <td style="text-align: center">{{ item.fee_name }}</td>
                  <td style="text-align: center">{{ moneyFormatter(item.amount) }}</td>
                  <td style="text-align: center">{{ item.check_name }}</td>
                </template>
              </tr>
            </template>
            <template v-if="GLOBAL.departmentMaps[data.department].name === '国贸部' ||
              GLOBAL.departmentMaps[data.department].name === '进销存' ||
              GLOBAL.departmentMaps[data.department].name === '堆场'">
              <tr>
                <td :colspan="GLOBAL.departmentMaps[data.department].name === '进销存'?4:3">
                  <span class="weightFont">合计: </span>
                  <template v-if="data.currency ==='CNY'">
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                  <template v-else>
                    <span class="weightFont">{{ list[0] ? list[0].currency_cn_name : '' }} </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                </td>
                <td class="weightFont" style="text-align: right">{{ this.orderData.currency }} {{ moneyFormatter(this.money) }}</td>
              </tr>
              <tr>
                <td :colspan="GLOBAL.departmentMaps[data.department].name === '进销存'?5:4">
                  <span class="weightFont">合同号/有效期/信用期/顺延：</span>
                  <span class="weightFont" v-for="item in data.contract_list" :key="item.id">
                    {{ item.contract_no }}
                    <span v-if="item.is_no_term === 0">/ {{ item.contract_termination_date?moment(item.contract_termination_date).format('YYYY-MM-DD'):moment(item.contract_end_date).format('YYYY-MM-DD') }}</span>
                    <span>/ {{ item.credit_period?item.credit_period:'无' }}</span>
                    <span>/ {{ item.contract_extension_deadline?item.contract_extension_deadline:'无' }}</span>
                    <a-divider type="vertical" />
                  </span>
                </td>
              </tr>
              <tr>
                <td :colspan="GLOBAL.departmentMaps[data.department].name === '进销存'?5:4">
                  <span class="weightFont">备注：</span>
                  <span class="weightFont">{{ data.apply_remark }}</span>
                </td>
              </tr>
            </template>
            <template v-else-if="GLOBAL.departmentMaps[data.department].name === '综合部' ||
              GLOBAL.departmentMaps[data.department].name === '总经办'">
              <tr>
                <td colspan="2">
                  <span class="weightFont">合计: </span>
                  <template v-if="data.currency ==='CNY'">
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                  <template v-else>
                    <span class="weightFont">{{ list[0] ? list[0].currency_cn_name : '' }} </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                </td>
                <td class="weightFont" style="text-align: right">{{ this.orderData.currency }} {{ moneyFormatter(this.money) }}</td>
                <td class="weightFont" style="text-align: right"></td>
              </tr>
              <tr>
                <td colspan="4">
                  <span class="weightFont">合同号/有效期/信用期/顺延：</span>
                  <span class="weightFont" v-for="item in data.contract_list" :key="item.id">
                    {{ item.contract_no }}
                    <span v-if="item.is_no_term === 0">/ {{ item.contract_termination_date?moment(item.contract_termination_date).format('YYYY-MM-DD'):moment(item.contract_end_date).format('YYYY-MM-DD') }}</span>
                    <span>/ {{ item.credit_period?item.credit_period:'无' }}</span>
                    <span>/ {{ item.contract_extension_deadline?item.contract_extension_deadline:'无' }}</span>
                    <a-divider type="vertical" />
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <span class="weightFont">备注：</span>
                  <span class="weightFont">{{ data.apply_remark }}</span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">
                  <span class="weightFont">合计: </span>
                  <template v-if="data.currency ==='CNY'">
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                  <template v-else>
                    <span class="weightFont">{{ list[0] ? list[0].currency_cn_name : '' }} </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                    <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                  </template>
                </td>
                <td class="weightFont" style="text-align: right">{{ this.orderData.currency }} {{ moneyFormatter(this.money) }}</td>
              </tr>
              <tr>
                <td colspan="4">
                  <span class="weightFont">合同号/有效期/信用期/顺延：</span>
                  <span class="weightFont" v-for="item in data.contract_list" :key="item.id">
                    {{ item.contract_no }}
                    <span v-if="item.is_no_term === 0">/ {{ item.contract_termination_date?moment(item.contract_termination_date).format('YYYY-MM-DD'):moment(item.contract_end_date).format('YYYY-MM-DD') }}</span>
                    <span>/ {{ item.credit_period?item.credit_period:'无' }}</span>
                    <span v-if="item.contract_extension_deadline">/ {{ item.contract_extension_deadline }}</span>
                    <a-divider type="vertical" />
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <span class="weightFont">备注：</span>
                  <span class="weightFont">{{ data.apply_remark }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <span style="display: inline-block;width: 30px;padding-left:10px;"></span>
      </div>

      <!-- 发票信息 -->
      <div style="margin-top: 20px" v-if="invoiceData">
        <p style="font-weight: 800;font-size: 16px">发票信息</p>
        <table>
          <tbody>
            <!-- 表格 header -->
            <tr>
              <th style="text-align: center">发票号</th>
              <th style="text-align: center">开票日期</th>
              <th style="text-align: center">发票金额</th>
            </tr>
            <!-- 表格 content -->
            <tr v-for="invoice in invoiceData" :key="invoice.id">
              <td style="text-align: center">{{ invoice.invoice_number }}</td>
              <td style="text-align: center">{{ invoice.invoice_date }}</td>
              <td style="text-align: center">{{ moneyFormatter(invoice.amount) }}</td>
            </tr>
            <!-- 合计 -->
            <tr>
              <td class="weightFont" colspan="2" style="text-align: center">合计</td>
              <td class="weightFont" style="text-align: center">{{ invoiceSumTotal }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p id="footer">
      <span>总经理：</span>
      <span>财务经理：</span>
      <span>分管领导：</span>
      <span v-if="this.GLOBAL.departmentMaps[data.department].name === '国贸部'">部门经理：</span>
      <span v-else-if="this.GLOBAL.departmentMaps[data.department].name === '进销存'">部门经理：</span>
      <span v-else-if="this.GLOBAL.departmentMaps[data.department].name === '堆场'">部门经理：张慧</span>
      <span v-else>部门经理： </span>
      <span v-if="this.GLOBAL.departmentMaps[data.department].name === '进销存'">经办人：</span>
      <span v-else>经办人：{{ userInfo.name }}</span>
    </p>
    <div v-if="adjustData && adjustData.adjust_order_dto">
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          {{ adjustData.business_order_num }} 调整单{{ adjustData.fms_sap_num ? '（SAP编号:' + adjustData.fms_sap_num + '）' : '' }}
        </a-divider>
      </p>
      <div v-for="detail in adjustData.adjust_order_dto.adjust_details" :key="detail.id">
        <a-tag style="font-weight: bold;font-size: 16px">{{ detail.adjust_type }}</a-tag>
        <span v-for="fee in detail.add_fee_dtos" :key="fee">{{ fee }}</span>
        <span v-for="fee in detail.delete_fee_dtos" :key="fee">{{ fee }}</span>
        <span v-if="detail.original_customer_name">由 <span style="font-weight: bold">{{ detail.original_customer_name}}</span> 改为 <span style="font-weight: bold">{{ detail.new_customer_name}}</span></span>
        <span v-if="detail.original_fee_class_name">由 <span style="font-weight: bold">{{ detail.original_fee_class_name}}</span> 改为 <span style="font-weight: bold">{{ detail.new_fee_class_name}}</span></span>
      </div>
      <div v-if="adjustData">
        <!-- <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left"> 审核信息 </a-divider>
        </p> -->
        <a-row :gutter="24">
          <a-col :span="24">
            调整原因:{{ adjustData.reason_description}}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6" v-for="flow in adjustData.adjust_flow_list" :key="flow.id">
            {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { numberToChinese } from '@/utils/util'
export default {
  name: 'FmsPayBill',
  components: {},
  props: {
    orderData: {
      type: Object,
      default: null
    },
    orderList: {
      type: Array,
      default: null
    },
    invoiceList: {
      type: Array,
      default: null
    },
    adjustPayData: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    orderData(val, oldVal) {
      this.data = val
    },
    orderList: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    },
    invoiceList(val, oldVal) {
      this.invoiceData = val
    },
    adjustPayData(val, oldVal) {
      this.data = val
    }
  },
  data() {
    return {
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      data: {},
      list: [],
      invoiceData: [],
      adjustData: {},
      userInfo: this.$store.getters.userInfo,
      year: moment().year(),
      month: moment().month() + 1,
      date: moment().date(),
      money: '',
      cnMoney: ''
    }
  },
  computed: {
    // 统计发票信息的合计金额
    invoiceSumTotal() {
      let totalMoney = 0
      for (let i = 0; i < this.invoiceData.length; i++) {
        totalMoney += parseFloat(this.invoiceData[i].amount)
      }
      return this.moneyFormatter(totalMoney)
    }
  },
  methods: {
    numberToChinese,
    moment,
    initData() {
      let money = 0
      this.orderList.forEach(item => {
        money += item.amount
      })
      this.money = money
      this.cnMoney = money.toFixed(2) + ''
      this.data = this.orderData
      this.list = this.orderList
      this.invoiceData = this.invoiceList
      this.adjustData = this.adjustPayData
      console.info(this.adjustPayData)
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.weightFont{
  font-weight: 1000;font-size: 16px
}
.title{
  width: 100%;
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 80%; margin: 0 auto; padding-top: 50px}
#print p, #print table { width: 100% }
table { border-collapse: collapse }
td, th { padding: 5px; border: 1px solid #ccc; }
/*th:first-of-type { width:80% }*/
/*th:last-of-type { width:20% }*/
#footer span { display: inline-block; width: 19% }
.custom-style{display: inline-block;width: 50px;border-bottom: 1px solid #cccccc;text-align: center;}
.show{display: inline;}
.hidden{display: none;}
</style>
